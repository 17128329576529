.GroupWidget {
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 34px;
    background: var(--basic-white-alpha-12, rgba(255, 255, 255, .12));
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    margin-bottom: 24px;
    will-change: opacity;
    flex-direction: row;
}

.GroupWidgetLeft,
.GroupWidgetRight {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.GroupWidgetLogo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
}

.GroupWidgetName {
    word-wrap: break-word;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    color: var(--color-text-primary);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    font-family: var(--font-display);
}

.GroupWidgetMetrics {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    gap: 16px;
}

.GroupWidgetMetrics div {
    display: flex;
    flex-direction: row;
}

.GroupWidgetMetrics div > svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.GroupWidgetJoinButton {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 25px;
    width: 100%;
    background: var(--basic-white-alpha-12, rgba(255, 255, 255, .12));
}

.GroupWidgetJoinButton svg path {
    stroke: #ffffff80
}
