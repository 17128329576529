.switcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 24px;
}

.switcherTab {
    flex: 1 0;
    text-align: center;
    color: #C047BC;
    padding: 8px;
}

.switcherTabActive {
    background-color: #C047BC;
    color: #fff;
    border-radius: 24px;
}