.PlaceAndScore {
    will-change: opacity;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 0;
}

.PlaceAndScoreAmount {
    font-size: 48px;
    line-height: 64px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-style: normal;
    margin-bottom: 14px;
}

.PlaceAndScoreAmountIcon {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    background-image: url(../../assets/images/heart-sm.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent
}

.PlaceAndScoreRating {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.PlaceAndScoreRatingValue {
    display: flex;
}

.PlaceAndScoreRatingInner {
    display: flex;
    align-items: center;
}

.PlaceAndScoreRatingLabel {
    margin: 0 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
}

.PlaceAndScoreRatingValueLaurel {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    font-size: 22px;
}

.PlaceAndScoreRatingValueLaurel:last-child {
    transform: scaleX(-1);
}