:root {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: var(--font-display)
}

[data-mode=light] {
  --color-bg-primary: var(--palette-dark-4);
  --color-bg-secondary: var(--palette-white);
  --color-bg-button-tertiary: var(--palette-transparent-grey-8);
  --color-bg-tertiary: var(--palette-transparent-dark-05);
  --color-text-primary: var(--palette-dark);
  --color-text-secondary: var(--palette-transparent-dark-60);
  --color-text-tertiary: var(--palette-transparent-dark-30);
  --color-text-fourth: var(--palette-transparent-dark-18);
  --color-bg-fourth: var(--palette-transparent-dark-08);
  --color-bg-fifth: var(--palette-transparent-white-12);
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-separator: var(--palette-transparent-dark-36);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-light);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-dark);
  --elevation-1: var(--elevation-light-1)
}

[data-mode=dark] {
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-fourth: var(--palette-transparent-white-08);
  --color-bg-fifth: var(--palette-transparent-white-12);
  --color-text-primary: var(--palette-white);
  --color-text-secondary: var(--palette-transparent-white-80);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-light);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1)
}

*,
*:before,
*:after {
  box-sizing: border-box
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0
}

ul[role=list],
ol[role=list] {
  list-style: none
}

html:focus-within {
  scroll-behavior: smooth
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5
}

a:not([class]) {
  text-decoration-skip-ink: auto
}

img,
picture {
  max-width: 100%;
  display: block
}

input,
button,
textarea,
select {
  font: inherit
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto
  }

  *,
  *:before,
  *:after {
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: .01ms !important;
    scroll-behavior: auto !important
  }
}

:root {
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 16px;
  --safe-area-left: 16px;
  --safe-area-right: 16px;
  --palette-dark-4: #efeff3;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(235, 235, 245, .05);
  --palette-transparent-white-08: rgba(255, 255, 255, .08);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(235, 235, 245, .18);
  --palette-transparent-white-30: rgba(235, 235, 245, .3);
  --palette-transparent-white-60: rgba(235, 235, 245, .6);
  --palette-transparent-white-80: rgba(235, 235, 245, .8);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-08: rgba(0, 0, 0, .08);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #007aff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-theme-button-color: red;
  --cupido-accent: #C047BC;
  --outline: #4BA5FF
}

html {
  height: 100%
}

body {
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  margin: 0;
  -webkit-overflow-scrolling: touch !important
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%
}

/* App */

.Page {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none
}

.Page:before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  background: linear-gradient(180deg, #C047BC, rgba(0, 0, 0, 1));
  border-bottom-left-radius: 400%;
  border-bottom-right-radius: 400%;
  height: 60vh;
}

/* Toast */
.toast-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: #FF9CFC;
  padding: 8px 12px;
}

.toast-msg--error {
  color: #ef2a2a;
}

.toast-icon {
  font-size: 24px;
}