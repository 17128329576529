.ClickerCoin {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    font-size: 72px;
    font-weight: 700;
    text-align: center;
    line-height: 280px;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    transition: transform .1s ease-out;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    touch-action: none;
    user-zoom: fixed;
    transform-origin: center;
    will-change: transform;
    position: relative;
    z-index: 1
}

.ClickerCoin svg {
    padding: 0 50px;
}

.ClickerCoinAmount {
    position: absolute;
    top:0;
    left: 0;
    z-index: 90000;
    pointer-events: none;
}

.ClickerCoinAmountDiv {
    top: 0;
    left: 0;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none
}

.ClickerCoinAmountDivIcon {
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 30px;
}

.ClickerCoinAmountDivText {
    position: absolute;
    font-size: 42px;
    font-weight: bold;
    left: 0;
    top: 0;
    opacity: 0.5;
    text-align: center;
}

.ClickerNote {
    color: #fff;
    text-align: center;
    font-size: 16px;
    position: relative;
    opacity: 0.4;
}