.LikesList {
    position: relative;
    display: flex;
    flex-direction: column;
}

.LikesListItem {
    -webkit-user-select: none;
    user-select: none;
    transition: background-color ease;
    transition-duration: .3s;
    cursor: pointer;
    min-height: 64px;
    padding: 8px 0;
    margin-bottom: 8px;
}

.LikesListItem:last-child {
    margin-bottom: 0;
}

.LikesListItemInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    align-items: center;    
}

.LikesListItemImage {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 24px;
    border-radius: 48px;
}

.LikesListItemAvatar {
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.LikesListItemContent {
    overflow: hidden;
    flex-grow: 1;
}

.LikesListItemContentTitle {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 12px;
    overflow: hidden;
    margin-bottom: 2px;
}

.LikesListItemActions {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.LikesListBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    height: 32px;
    width: 32px;
    background-color: transparent;
}

.LikesListBtnSkip {
    color: #fff;
}

.LikesListBtnMatch {
    color: #C047BC;
}

.LikesListModal {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    width: 80%;
    border-radius: 32px;
    overflow: hidden;
}

.LikesListModal:focus-visible {
    outline: none;
}

.LikesListModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
}

.LikesListModalClose {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
    display: flex;
    background-color: transparent;
    border: none;
    color: #fff;
}

.LikesListModalContent {
    position: relative;
}

.LikesListModalText {
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0), black);
    color: #fff;
    padding: 32px;
}

.LikesListItemTitle {
    color: white;
}