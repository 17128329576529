.balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 24px;
}

.balance:before {
    content: '';
    height: 32px;
    width: 32px;
    display: flex;
    background-image: url(../../assets/images/heart-sm.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent
}

.balanceIcon {
    padding: 8px;
    background-color: #C047BC;
    border-radius: 50%;
    color: #fff;
    font-size: 32px;
}

.addIcon {
    color: #fff;
    font-size: 20px;
    margin-left: 8px;
}

.addBalance {
    display: flex;
    background-color: rgba(192, 71, 188, 0.5);
    color: #fff;
    text-decoration: none;
    padding: 4px 16px;
    border-radius: 24px;
    align-items: center;
}

.balanceWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}