.PageProfile {
    color: #fff;
    height: 100vh;
    padding-top: 16px;
    padding-bottom: 16px;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
}

.PageProfile:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    background: linear-gradient(180deg, #C047BC, rgba(0,0,0,1));
    border-bottom-left-radius: 400%;
    border-bottom-right-radius: 400%;
    height: 60vh;
}

.PageProfileHeader {
    width: 100%;
    padding-top: 100%;
    position: relative;
    margin-bottom: 32px;
}

.PageProfileImage {
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: 100%;
    color: #000;
    border-radius: 32px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    box-shadow: 0px 0px 24px 20px rgba(0,0,0,0);
}

.PageProfileInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 32px;
    background: linear-gradient(180deg, rgba(0,0,0,0), black);
}

.PageProfileName {
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.PageProfileForm {
    position: relative;
    width: 100%;
}