.Loader {
  position: absolute;
  left:50%;
  top:50%;
  width: 100%;
  height: 100%;
  display: flex;
  transform: translate(-50%, -50%);
}

.LoaderHearts {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-left: -80px;
  margin-top: -55px;
  width: 160px;
  height: 110px;
  overflow: visible;
}

.LoaderHeartsGroup {
  transform-origin: 0 90px;
}

.LoaderHeartsLeft,
.LoaderHeartsRight {
  stroke: #b8b8b8;
  stroke-dasharray: 308.522, 308.522;
  stroke-dashoffset: 308.522;
}

.LoaderHeartsLeft {
  animation: left-heart-anim 5s 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  transform: translateX(0);
}

.LoaderHeartsRight {
  animation: right-heart-anim 5s 1s alternate;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  transform: translate(62px, 12px);
}

@-webkit-keyframes left-heart-anim {
  12% {
      stroke-dashoffset: 188.522;
      stroke: #FF9CFC;
      fill: transparent;
  }
  31% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
  41% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
  43% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
  85% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 0;
  }
  100% {
      stroke-dashoffset: 0;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      stroke: #FF9CFC;
      fill: #FF9CFC;
      opacity: 1;
  }
}

@keyframes left-heart-anim {
  12% {
      stroke-dashoffset: 188.522;
      stroke: #FF9CFC;
      fill: transparent;
  }
  31% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      transform: translateX(0);
  }
  41% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      transform: translateX(0);
  }
  43% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      transform: translateX(0);
      opacity: 1;
  }
  85% {
      stroke-dashoffset: 0;
      fill: transparent;
      stroke: #FF9CFC;
      transform: translateX(0);
      opacity: 0;
  }
  100% {
      stroke-dashoffset: 0;
      transform: translateY(-2px);
      stroke: #FF82FB;
      fill: #FF82FB;
      opacity: 1;
  }
}

@keyframes right-heart-anim {
  12% {
      stroke-dashoffset: 188.522;
      fill: transparent;
      stroke: #FF9CFC;
  }
  31% {
      stroke-dashoffset: 0;
      transform: translate(62px, 12px);
      fill: transparent;
      stroke: #FF9CFC;
  }
  41% {
      stroke-dashoffset: 0;
      transform: translate(62px, 12px);
      fill: transparent;
      stroke: #FF9CFC;
  }
  43% {
      stroke-dashoffset: 0;
      transform: translate(62px, 12px);
      opacity: 1;
      fill: transparent;
      stroke: #FF9CFC;
  }
  85% {
      stroke-dashoffset: 0;
      transform: translate(62px, 12px);
      opacity: 0;
      fill: transparent;
      stroke: #FF9CFC;
  }
  100% {
      stroke-dashoffset: 0;
      transform: translate(62px, 10px);
      stroke: #C047BC;
      fill: #C047BC;
      opacity: 1;
  }
}

@keyframes heart-anim {
  55% {
      stroke-dashoffset: 308.522;
      fill: transparent;
  }
  70% {
      stroke-dashoffset: 0;
      fill: transparent;
  }
  87% {
      stroke-dashoffset: 0;
      fill: #A1369E;
  }
  100% {
      stroke-dashoffset: 0;
      fill: #A1369E;
  }
}