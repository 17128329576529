.UsersList {
    position: relative;
    border-radius: 16px;
    background: rgba(255, 255, 255, .12);
    padding: 8px 0;
    display: flex;
    flex-direction: column;
}

.UsersListItem {
    -webkit-user-select: none;
    user-select: none;
    transition: background-color ease;
    transition-duration: .3s;
    cursor: pointer;
    min-height: 64px;
    padding: 8px 16px;
    margin-bottom: 8px;
}

.UsersListItem:last-child {
    margin-bottom: 0;
}

.UsersListItemInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    align-items: center;    
}

.UsersListItemImage {
    background: #fff;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 48px;
    background-size: cover;
}

.UsersListItemAvatar {
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.UsersListItemContent {
    overflow: hidden;
    flex-grow: 1;
}

.UsersListItemContentTitle {
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    font-weight: 510;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 12px;
    overflow: hidden;
    margin-bottom: 2px;
}

.UsersListItemContentScore {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #ffffffbf;
    gap: 4px;
}

.UsersListItemContentReward {
    position: relative;
    padding-left: 4px;
    font-weight: 600;
    font-family: var(--font-display-ios-rounded);
}

.UsersListItemInviteReward{
    margin-left: auto;
    color: var(--notcoin-orange);
    font-family: var(--font-display-ios-rounded);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}