.RL-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.RL-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
}

.RL-item:first-child .RL-index {
    color: gold
}

.RL-item:nth-child(2) .RL-index {
    color: silver
}

.RL-item:nth-child(3) .RL-index {
    color: #CD7F32;
}

.RL-index {
    color: #fff;
    width: 12px;
}

.RL-img {
    height: 48px;
    border-radius: 50%;
    margin-left: 0;
    margin: 0 12px;
}

.RL-itemContent {
    color: #fff;
    display: flex;
    flex-direction: column;
}

.RL-name {
    font-size: 16px;
}

.RL-geo {
    color: rgba(255,255,255,0.4);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.RL-coins {
    color: #C047BC;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.RL-coins:after {
    content: '';
    height: 16px;
    width: 16px;
    display: flex;
    background-image: url(../../assets/images/heart-sm.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}

.RL-direct {
    border: none;
    background: none;
    color: #C047BC;
    margin-left: auto;
    display: flex;
}