.PageBalance {
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 16px;
    padding-bottom: 20px;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 1;
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
}

.PageBalance:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    background: linear-gradient(180deg, #C047BC, rgba(0, 0, 0, 1));
    border-bottom-left-radius: 400%;
    border-bottom-right-radius: 400%;
    height: 60vh;
}