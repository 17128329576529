.textarea {
    display: inline-flex;
    padding-top: 12px;
    padding-bottom: 12px; 
    padding-left: 24px;
    padding-right: 24px; 
    border-radius: 12px; 
    border-width: 1px; 
    border-color: #F3F4F6;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    appearance: none;
    background-color: transparent;
    color: #fff;
}

.textarea--resize-none {
    resize: none;
}

.textarea-note {
    color: #ccc;
    text-align: right;
    font-size: 14px;
}

.textarea-error {
    margin-top: 0.25rem; 
    font-size: 1rem;
    line-height: 1.5rem; 
}