.GroupsList {
    position: relative;
    border-radius: 16px;
    background: rgba(255, 255, 255, .12);
    padding: 8px 0;
    display: flex;
    min-height: 80px;
    flex-direction: column;
}

.GroupsListItem {
    -webkit-user-select: none;
    user-select: none;
    transition: background-color ease;
    transition-duration: .3s;
    cursor: pointer;
    min-height: 64px;
    padding: 8px 16px;
    margin-bottom: 8px;
}

.GroupsListItem:last-child {
    margin-bottom: 0;
}

.GroupsListItemInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    align-items: center;    
}

.GroupsListItemImage {
    background: #fff;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 48px;
}

.GroupsListItemAvatar {
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.GroupsListItemContent {
    overflow: hidden;
    flex-grow: 1;
}

.GroupsListItemMetrics {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.GroupsListItemContentTitle {
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 12px;
    overflow: hidden;
    margin-bottom: 4px;
}

.GroupsListItemContentScore {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #ffffffbf;
    gap: 4px;
}

.GroupsListItemContentReward {
    position: relative;
    padding-left: 4px;
    font-weight: 600;
    font-family: var(--font-display);
}

.GroupsListItemInviteReward{
    margin-left: auto;
    color: var(--dot-accent);
    font-family: var(--font-display);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}