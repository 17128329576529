.PageGroups {
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 16px;
    padding-bottom: 20px;
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 1;
}

.btnTakePlace {
    position: relative;
    margin: 24px auto;
    border: none;
    /* background-color: #C047BC; */
    background-color: transparent;
    border: 1px solid #C047BC;
    border-radius: 32px;
    text-decoration: none;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #C047BC;
    gap: 16px;
}

.PageGroupsHeader {
    position: relative;
    padding: 32px 0;
}

.PageGroupsTitleWrapper {
    color: #fff;
    position: relative;
    text-align: center;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.PageGroupsTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 34px;
    margin: 0 16px;
}

.PageGroupsSubtitle {
    color: #eee;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    text-align: center;
}

.PageGroupsCreateGroupButton {
    width: 100%;
    margin-bottom: 24px;
    background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
    box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
}